import React from 'react';
import {RoomInput} from '../../bedsandrooms.type';
import {BedroomSummary} from '../bedroomSummary/BedroomSummary';

interface ContentSummaryProps {
    rooms: Array<RoomInput>;
}

export const ContentSummary: React.FunctionComponent<ContentSummaryProps> = (props) => {
    const {rooms} = props;

    return (
        <div className="container-fluid">
            <div className="row">
                {rooms.map((room, index) => {
                    return <BedroomSummary room={room} key={index} />;
                })}
            </div>
        </div>
    );
};
