import {Item} from '../components/bedsandrooms.type';
import _ from 'lodash';

export class BedHelper {
    static getBedsSummary = (beds: Array<{bed_type: Item; bed_size: Item}>): Array<{size: string; type: string; total: number}> => {
        const result = [];
        const groupBySize = _.groupBy(beds, 'bed_size.name');
        for (const size in groupBySize) {
            const bedsBySize = groupBySize[size];
            const groupByType = _.groupBy(bedsBySize, 'bed_type.name');
            for (const type in groupByType) {
                result.push({size, type, total: groupByType[type].length});
            }
        }

        return result;
    };
}
