import React, {useState, useEffect} from "react";
import {Room, RoomType, Bed, BedInput} from "../../../bedsandrooms.type";
import {Button} from "@common/components";
import {UnitsService} from "@common/units-api";
import {BedItem} from "../../bed/BedItem";
import _ from "lodash";
import styles from "./RoomForm.module.scss";
import {authManager} from "@common/authentication";
import {AdminManagerService} from "../../../../services/admin-manager.service";

interface RoomInput {
    room: Room;
    setRoom: Function;
    cancelRoom: Function;
    existUnassignedRoom: boolean;
}

const UNASSIGNED_BED_TYPE_ID = 10;

export const RoomForm: React.FunctionComponent<RoomInput> = (props) => {
    const [roomTypeCollection, setRoomTypeCollection] = useState([]);

    const {room, setRoom, cancelRoom, existUnassignedRoom} = props;

    const adminManager = new AdminManagerService();

    useEffect(() => {
        (async () => {
            try {
                if (roomTypeCollection.length) return;

                const unitService = new UnitsService(authManager.getJwt());
                const roomTypes: Array<RoomType> = await unitService.getAllRoomTypes();

                adminManager.getUnitDisplay() === true || existUnassignedRoom
                    ? setRoomTypeCollection(roomTypes.filter((type) => type.attributes.id !== UNASSIGNED_BED_TYPE_ID))
                    : setRoomTypeCollection(roomTypes);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const handleRoomTypeChange = (e: any) => {
        const target = e.target as HTMLInputElement;
        const valueArr = target.value.split("_");

        const roomClone = _.cloneDeep(room);
        roomClone.attributes.room_type = {id: +valueArr[0], name: valueArr[1]};
        setRoom(roomClone);
    };

    const handleButtonAddBed = () => {
        const roomClone = _.cloneDeep(room);
        roomClone.attributes?.beds?.push({index: roomClone.attributes.beds.length});
        setRoom(roomClone);
    };

    const handleCancelButton = () => cancelRoom(room);

    const handleSaveButtton = () => {
        const roomClone = _.cloneDeep(room);
        roomClone.statusUI = "lock";
        setRoom(roomClone);
    };

    const setBed = (bed: BedInput) => {
        const roomClone = _.cloneDeep(room);
        roomClone.attributes.beds[bed.index] = bed;
        setRoom(roomClone);
    };

    const cancelBed = (bed: Bed) => {
        const roomClone = _.cloneDeep(room);
        _.remove(roomClone.attributes.beds, (b) => b.index === bed.index);

        roomClone.attributes.beds = roomClone.attributes.beds.map((r, i) => ({...r, index: i}));
        setRoom(roomClone);
    };

    return (
        <div className={styles.roomItem}>
            <form>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <label>
                                {props.room.attributes.room_type
                                    ? `${props.room.attributes.room_type.name} ${props.room.attributes.room_number || ""}`
                                    : "Room"}
                            </label>
                            <select
                                className="form-control"
                                id={`roomType_${room.index}`}
                                value={`${room.attributes?.room_type?.id}_${room.attributes?.room_type?.name}`}
                                onChange={handleRoomTypeChange}
                            >
                                <option value="">--</option>
                                {roomTypeCollection.map((roomType: RoomType, index) => {
                                    return (
                                        <option key={index} value={`${roomType.attributes.id}_${roomType.attributes.name}`}>
                                            {roomType.attributes.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                {room.attributes.beds?.map((bed, index) => {
                    return <BedItem key={index} bed={bed} setBed={setBed} cancelBed={cancelBed} />;
                })}
                <div className="form-row">
                    <div className="col">
                        <button type="button" className="btn btn-link left" onClick={handleButtonAddBed}>
                            + Add Bed
                        </button>
                    </div>
                </div>
                <div className="form-row">
                    <span>&nbsp;</span>
                </div>
                <div className="form-row">
                    <div className="col">
                        <Button type="button" variant="primary" color="white" className="right" disabled={!room.valid} onClick={handleSaveButtton}>
                            Save Draft
                        </Button>
                        <span className="right">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button type="button" variant="light" color="black" className="right" onClick={handleCancelButton}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};
