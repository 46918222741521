import React from 'react';
import {RoomInput} from '../../bedsandrooms.type';
import {BedHelper} from '../../../helpers/BedHelper';
import _ from 'lodash';
import styles from './BedroomSummary.module.scss';
import {Configuration} from '@common/configuration';

interface BedroomSummaryProps {
    room: RoomInput;
    key?: number;
}

export const BedroomSummary: React.FunctionComponent<BedroomSummaryProps> = (props) => {
    const {room} = props;

    const getTitle = () => {
        let title = `${_.capitalize(room.attributes.room_type.name)} ${room.attributes.room_number}` ;

        return (
            <strong
                className={+room.attributes.room_type.id === Configuration.BEDROOM_TYPE.UNASSIGNED.ID ? styles.warningColor : null}
            >{`${title}`}</strong>
        );
    };

    return (
        <div className={`${styles.bedroomBox} col-md-2`}>
            <span className={styles.titleBedroom}>{getTitle()}</span>
            <ul className={styles.listBeds}>
                {BedHelper.getBedsSummary(room.attributes.beds).map((item, index) => {
                    return <li key={index}>{`${item.total} ${_.capitalize(item.size)} ${_.capitalize(item.type)}`}</li>;
                })}
            </ul>
        </div>
    );
};
