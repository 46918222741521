import React, {useState, useEffect} from "react";
import {RoomForm} from "./room/roomForm/RoomForm";
import {RoomLock} from "./room/roomLock/RoomLock";
import {Room} from "../bedsandrooms.type";
import {Button} from "@common/components";
import styles from "./BedsAndRoomsApp.module.scss";
import _ from "lodash";
import {authManager} from "@common/authentication";
import {UnitsService} from "@common/units-api";
import {useToasts} from "react-toast-notifications";
import {FaCircleNotch} from "react-icons/fa";
import {AdminManagerService} from "../../services/admin-manager.service";

interface BedsAndRoomsInput {}

const BEDROOM_ID = 8;
const UNASSIGNED_BED_TYPE_ID = 10;

export const BedsAndRoomsApp: React.FunctionComponent<BedsAndRoomsInput> = (props) => {
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [unit, setUnit] = useState({unitId: null, display: null, uuid: null});

    const {addToast} = useToasts();
    const adminManager = new AdminManagerService();
    const unitService = new UnitsService(authManager.getJwt());

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                const unitInfo = await getUnitInfo();
                if (!_.isEmpty(unitInfo)) {
                    setUnit(unitInfo);
                }

                const roomsResponse = await getRooms(unitInfo.uuid);
                if (!_.isEmpty(roomsResponse)) {
                    setRooms(roomsResponse);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const getUnitInfo = async (): Promise<{unitId: number; display: boolean; uuid: string}> => {
        const unitId = adminManager.getUnitId(),
            display = adminManager.getUnitDisplay(),
            uuid = await unitService.getUuidByLegacyUnitId(unitId);

        return {unitId, display, uuid};
    };

    const getRooms = async (uuid: string) => {
        try {
            if (_.isNil(uuid)) {
                addToast(
                    <div>
                        <h4>This unit hasn't been found.</h4>
                        <p>
                            If this is a brand new unit, you may need to wait a few minutes and try again. If the unit still fails, then contact
                            product or engineering to investigate the error.
                        </p>
                    </div>,
                    {
                        appearance: "info",
                        autoDismiss: false,
                    }
                );
                return [];
            }

            let roomsResponse = await unitService.getRoomsByUnit(uuid);

            if (_.isNil(roomsResponse)) return [];

            roomsResponse = _.sortBy(roomsResponse, (room) => (room.attributes.room_type.id === BEDROOM_ID ? +room.attributes.room_number : 1000));

            return roomsResponse?.map((room, index) => {
                return {
                    ...room,
                    statusUI: "lock",
                    index: index,
                    attributes: {
                        ...room.attributes,
                        beds: room.attributes.beds.map((bed, index) => {
                            return {...bed, index: index};
                        }),
                    },
                    deleted: false,
                };
            });
        } catch (error) {
            console.error("error getRooms", error);
            addToast("error with rooms", {appearance: "error"});
            return [];
        }
    };

    const reloadSummary = () => {
        window.parent.postMessage({action: "click", type: "reloadSummary"}, "*");
    };

    const reloadRooms = async () => {
        const roomsResponse = await getRooms(unit.uuid);
        if (!_.isEmpty(roomsResponse)) {
            setRooms(roomsResponse);
        }
    };

    const setRoom = (room: Room) => {
        const roomsClone = _.cloneDeep(rooms);

        //check room
        let roomValid = true;
        if (!room.attributes?.room_type?.id || !room.attributes?.beds) {
            roomValid = false;
        }

        for (const bed of room.attributes?.beds) {
            if (!bed.bed_size?.id || !bed.bed_type?.id) {
                roomValid = false;
                break;
            }
        }

        roomsClone[room.index] = {...room, valid: roomValid, edited: true};
        setRooms(roomsClone);
    };

    const addRoom = (room: Room) => {
        room = {
            ...room,
            index: rooms.length,
            attributes: {
                beds: [],
            },
        };

        const cloneRooms = _.cloneDeep(rooms) as any;
        cloneRooms.push(room);
        setRooms(cloneRooms);
    };

    const createNewRoom = () => {
        addRoom({
            valid: false,
            statusUI: "open",
            deleted: false,
        });
    };

    const cancelEditRoom = () => {
        reloadRooms();
    };

    const saveRooms = async () => {
        const info = authManager.getInfoFromAdmin<{user: string; unitId: number}>();

        setIsLoading(true);

        if (!unit.uuid) {
            addToast("error saving rooms, uuid was not found.", {appearance: "error"});
            setIsLoading(false);
            return;
        }

        const errors = [];
        const body = [];

        try {
            for (const room of rooms as Array<Room>) {
                const roomTypeId = room.attributes.room_type.id;
                const beds = room.attributes.beds.map((bed) => {
                    return {bedType: bed.bed_type.id, bedSize: bed.bed_size.id, id: bed.id};
                });
                if (room.deleted && !_.isNil(room.id)) {
                    body.push({roomId: room.id, action: "delete"});
                } else if (room.edited && !_.isNil(room.id)) {
                    body.push({roomId: room.id, roomTypeId: roomTypeId, beds: beds, action: "update"});
                } else if (room.edited) {
                    delete beds["id"];
                    body.push({roomTypeId: roomTypeId, beds: beds, action: "create"});
                }
            }
            await unitService.bulkRoom(unit.uuid, body, info.user);
        } catch (error) {
            errors.push(error);
            console.error(error);
        }

        errors.length > 0 ? addToast("error saving rooms", {appearance: "error"}) : addToast("saved rooms", {appearance: "success"});
        setIsLoading(false);
        reloadRooms();
        reloadSummary();
    };

    let disableBtnAddRomm = false;
    if (rooms.find((room: Room) => room.statusUI === "open")) {
        disableBtnAddRomm = true;
    }

    const totalBeds = rooms.map((room: Room) => (room.deleted !== true ? room?.attributes?.beds?.length : 0)).reduce((a, b) => a + b, 0);
    const totalRooms = rooms.filter((room) => room.deleted !== true).length;
    const existUnassignedRoom = _.some(rooms, (room) => {
        return room?.attributes?.room_type?.id === UNASSIGNED_BED_TYPE_ID;
    });
    return (
        <div className="container">
            <div className={styles.titleShelf}>
                <h4>Add beds and rooms</h4>
                <div className="info">
                    <span>{totalBeds} beds</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span>{totalRooms} rooms</span>
                </div>
                {isLoading ? <FaCircleNotch className="fa-spin" /> : null}
            </div>
            <div className={`container content-items ${styles.contentRooms}`}>
                <div className="items">
                    {rooms
                        .filter((room) => room.deleted !== true)
                        .map((room: Room, index) => {
                            if (room.statusUI === "open") {
                                return (
                                    <RoomForm
                                        key={index}
                                        setRoom={setRoom}
                                        room={room}
                                        cancelRoom={cancelEditRoom}
                                        existUnassignedRoom={existUnassignedRoom}
                                    />
                                );
                            } else {
                                return <RoomLock key={index} room={room} setRoom={setRoom} />;
                            }
                        })}
                </div>
                <button type="button" className="btn btn-link left" onClick={createNewRoom} disabled={disableBtnAddRomm || isLoading}>
                    + Add Room
                </button>
            </div>
            <div className="content-footer">
                <Button
                    className="right"
                    type="button"
                    variant="primary"
                    color="white"
                    disabled={disableBtnAddRomm || rooms.length === 0 || isLoading}
                    onClick={saveRooms}
                >
                    Save All
                </Button>
            </div>
        </div>
    );
};
