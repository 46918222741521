import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {BedsToRoomPreviewApp} from "../components/bedsToRoomPreview/BedsToRoomPreviewApp";
import {BedsAndRoomsApp} from "../components/bedsToRoomSideBar/BedsAndRoomsApp";

class RouterApp extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/details" component={BedsAndRoomsApp} />
                    <Route path="/" component={BedsToRoomPreviewApp} />
                </Switch>
            </Router>
        );
    }
}

export default RouterApp;
