import React, { useState, useEffect } from "react";
import { BedSize, BedType, BedInput } from "../../bedsandrooms.type";
import { FaTimes } from "react-icons/fa";
import styles from "./BedItem.module.scss";
import { UnitsService } from "@common/units-api";
import _ from "lodash";
import { authManager } from "@common/authentication";
import {Configuration} from "@common/configuration";

interface BedProps {
    bed?: BedInput;
    setBed: Function;
    cancelBed: Function;
}

export const BedItem: React.FunctionComponent<BedProps> = (props) => {
    const [bedSizeCollection, setBedSizeCollection] = useState([]),
        [bedTypeCollection, setBedTypeCollection] = useState([]),
        [showBtnRemove, setShowBtnRemove] = useState(false);

    const { bed, setBed, cancelBed } = props;

    useEffect(() => {
        (async () => {
            try {
                if (bedSizeCollection.length > 0 || bedTypeCollection.length > 0) {
                    return;
                }

                const unitService = new UnitsService(authManager.getJwt()),
                    bedSizes: Array<BedSize> = await unitService.getAllBedSizes(),
                    bedTypes: Array<BedType> = await unitService.getAllBedTypes();

                setBedSizeCollection(bedSizes);
                setBedTypeCollection(bedTypes);
            } catch (error) {
                console.error(error);
            }
        })();
    });

    const handleBedSizeChange = (e: any) => {

        const target = e.target as HTMLInputElement;
        const valueArr = target.value.split("_");
        const bedClone = _.cloneDeep(bed);

        if(+valueArr[0] == Configuration.BED.CRIB.SIZE_ID)
        {
            bedClone.bed_type= bedTypeCollection.find(x=> x.id == Configuration.BED.CRIB.TYPE_ID)?.attributes
        }

        if(+valueArr[0] == Configuration.BED.CHILD.SIZE_ID)
        {
            bedClone.bed_type= bedTypeCollection.find(x=> x.id == Configuration.BED.CHILD.TYPE_ID)?.attributes
        }

        if(![Configuration.BED.CHILD.SIZE_ID,Configuration.BED.CRIB.SIZE_ID].includes( +valueArr[0]))
        {
            if([Configuration.BED.CRIB.TYPE_ID,Configuration.BED.CHILD.TYPE_ID].includes(bedClone?.bed_type?.id))
            {
                bedClone.bed_type =undefined;
            }
        }


        bedClone.bed_size = { id: +valueArr[0], name: valueArr[1] };
        setBed(bedClone);
    };

    const handleBedTypeChange = (e: any) => {
        const target = e.target as HTMLInputElement;
        const valueArr = target.value.split("_");
        const bedClone = _.cloneDeep(bed);


        if(+valueArr[0] == Configuration.BED.CRIB.TYPE_ID)
        {
            bedClone.bed_size= bedSizeCollection.find(x=> x.id == Configuration.BED.CRIB.SIZE_ID)?.attributes
        }

        if(+valueArr[0] == Configuration.BED.CHILD.TYPE_ID)
        {
            bedClone.bed_size= bedSizeCollection.find(x=> x.id == Configuration.BED.CHILD.SIZE_ID)?.attributes
        }

        if(![Configuration.BED.CHILD.TYPE_ID,Configuration.BED.CRIB.TYPE_ID].includes( +valueArr[0]))
        {
            if([Configuration.BED.CRIB.SIZE_ID,Configuration.BED.CHILD.SIZE_ID].includes(bedClone?.bed_size?.id))
            {
                bedClone.bed_size =undefined;
            }
        }

        bedClone.bed_type = { id: +valueArr[0], name: valueArr[1] };
        setBed(bedClone);
    };

    return (
        <div
            className="form-row"
            onMouseEnter={() => setShowBtnRemove(true)}
            onMouseLeave={() => setShowBtnRemove(false)}
        >
            <div className="col">
                <div className="form-group">
                    <label>Bed size</label>
                    <select
                        className="form-control"
                        id={`bedSize_${bed.index}`}
                        onChange={handleBedSizeChange}
                        value={`${bed.bed_size?.id}_${bed.bed_size?.name}`}
                    >
                        <option value="">--</option>
                        {bedSizeCollection.map((bedSize: BedSize, index) => {
                            return (
                                <option key={index} value={`${bedSize.attributes.id}_${bedSize.attributes.name}`}>
                                    {bedSize.attributes.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label>Bed type</label>
                    <select
                        className="form-control"
                        id={`bedType_${bed.index}`}
                        onChange={handleBedTypeChange}
                        value={`${bed.bed_type?.id}_${bed.bed_type?.name}`}
                    >
                        <option value="">--</option>
                        {bedTypeCollection.map((bedType: BedType, index) => {
                            return (
                                <option key={index} value={`${bedType.attributes.id}_${bedType.attributes.name}`}>
                                    {bedType.attributes.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className="col-2">
                <div className={`${styles.icoBtnRemove} ${showBtnRemove ? "show" : "hide"}`}>
                    <FaTimes
                        onClick={() => {
                            cancelBed(bed);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
