import React, {useState, useEffect} from 'react';
import {authManager} from '@common/authentication';
import {UnitsService} from '@common/units-api';
import styles from './BedsToRoomPreviewApp.module.scss';
import {HeaderSummary} from './headerSummary/HeaderSummary';
import {RoomInput} from '../bedsandrooms.type';
import {ContentSummary} from './contentSummary/ContentSummary';
import _ from 'lodash';
import {Configuration} from '@common/configuration';

interface BedsToRoomPreviewProps {}

export const BedsToRoomPreviewApp: React.FunctionComponent<BedsToRoomPreviewProps> = (props) => {
    const [rooms, setRooms] = useState<Array<RoomInput>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (!_.isEmpty(rooms)) return;

                const unitService = new UnitsService(authManager.getJwt()),
                    info = authManager.getInfoFromAdmin<{user: string; unitId: number}>(),
                    uuid = await unitService.getUuidByLegacyUnitId(info.unitId),
                    roomsResponse = await unitService.getRoomsByUnit(uuid);
                if (!_.isNil(roomsResponse)) {
                    setRooms(sortRooms(roomsResponse));
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [rooms]);

    //resize iframe for each render
    useEffect(() => {
        const height = document.getElementById('root').clientHeight;
        window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
    });

    //Order rooms, unassigned rooms always at the top.
    const sortRooms = (rooms: Array<RoomInput>) => {
        const sortRooms = _.sortBy(rooms, (room) =>
            room.attributes.room_type.id === Configuration.BEDROOM_TYPE.BEDROOM.ID ? +room.attributes.room_number : 1000,
        );
        const unassignedRoom = sortRooms.find((room) => +room.attributes.room_type.id === Configuration.BEDROOM_TYPE.UNASSIGNED.ID);
        if (!_.isNil(unassignedRoom)) {
            _.remove(sortRooms, (room) => +room.attributes.room_type.id === Configuration.BEDROOM_TYPE.UNASSIGNED.ID);
            sortRooms.unshift(unassignedRoom);
        }

        return sortRooms;
    };

    return (
        <div className={`${styles.summaryApp}`}>
            {isLoading ? (
                <div className="row">
                    <div className={`col ${styles.centerContent}`}>
                        <h6>Loading data...</h6>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="container-fluid">
                        <div className="row">
                            <HeaderSummary rooms={rooms} />
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-auto">
                                {rooms.length > 0 ? <p className={styles.watermark}>SLEEPING ARRANGEMENTS</p> : <span></span>}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <ContentSummary rooms={rooms} />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
