import React from 'react';
import _ from 'lodash';
import styles from './HeaderSummary.module.scss';
import {Item, RoomInput} from '../../bedsandrooms.type';
import {Button} from '@common/components';
import {BiDoorOpen, BiBed} from 'react-icons/bi';
import {BedHelper} from '../../../helpers/BedHelper';
import {Configuration} from '@common/configuration';

interface HeaderSummaryProps {
    rooms: Array<RoomInput>;
}

export const HeaderSummary: React.FunctionComponent<HeaderSummaryProps> = (props) => {
    const {rooms} = props;

    const getBeds = (): Array<{bed_type: Item; bed_size: Item}> => {
        const beds = [];
        if (_.isEmpty(rooms)) return beds;

        for (const room of rooms) {
            for (const bed of room.attributes.beds) {
                beds.push(bed);
            }
        }

        return beds;
    };

    const getTotalBeds = () => {
        return getBeds().length;
    };

    const getDetailByBed = () => {
        let str = '';
        const beds = getBeds();
        const summary = BedHelper.getBedsSummary(beds) as Array<{size: string; type: string; total: number}>;

        for (const item of summary) {
            str += `${item.total} ${_.capitalize(item.size)} ${_.capitalize(item.type)}, `;
        }

        return str.substr(0, str.length - 2) + '.';
    };

    const getTotalBedrooms = () => {
        return rooms.filter((room) => room.attributes.room_type.id === Configuration.BEDROOM_TYPE.BEDROOM.ID).length;
    };

    const handleOpenDetailClick = () => {
        window.parent.postMessage({action: 'click', type: 'showShelf'}, '*');
    };

    const totalBeds = getTotalBeds();
    const summary = getDetailByBed();
    return (
        <div className="container-fluid">
            <div className="row align-items-end">
                <div className="col-auto">
                    <h2>Rooms and Beds</h2>
                </div>
                <div className="col-auto">
                    <Button className="right" color="white" type="button" variant="primary" onClick={handleOpenDetailClick}>
                        {getTotalBedrooms() > 0 ? `Edit` : `Add`} room & bed details
                    </Button>
                </div>
            </div>
            {rooms.length > 0 ? (
                <div className="row">
                    <div className="col-auto">
                        <p>
                            <span className={styles.icoSummary}>
                                <BiDoorOpen />
                            </span>
                            <strong>{getTotalBedrooms() === 1 ? `1 Bedroom` : `${getTotalBedrooms()} Bedrooms`}</strong>
                        </p>
                    </div>
                    <div className="col-auto">
                        <p>
                            <span className={styles.icoSummary}>
                                <BiBed />
                            </span>
                            <strong>{totalBeds} Beds</strong> <span className={styles.pointDivision}>&#8226;</span>
                            <span>{summary}</span>
                        </p>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-auto">
                        <br />
                        <p>
                            Details about the rooms and beds haven't been added.{' '}
                            <a href="#" onClick={handleOpenDetailClick}>
                                Add room & beds details
                            </a>{' '}
                            to this listing.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};
