import React from "react";
import {Room, BedInput} from "../../../bedsandrooms.type";
import _ from "lodash";
import styles from "./RoomLock.module.scss";

interface RoomLockProps {
    room: Room;
    setRoom: Function;
}

const bedroomId = 8;

export const RoomLock: React.FunctionComponent<RoomLockProps> = (props) => {
    const {room, setRoom} = props;

    const handleButtonEdit = () => {
        const cloneRoom = _.cloneDeep(room);
        cloneRoom.statusUI = "open";
        setRoom(cloneRoom);
    };

    const handleButtonDelete = () => {
        const cloneRoom = _.cloneDeep(room);
        cloneRoom.deleted = true;
        setRoom(cloneRoom);
    };

    const getRoomName = () => {
        return `${props.room.attributes.room_type.name} ${props.room.attributes.room_number || ''}`
    };

    return (
        <div className={`${styles.roomItem} lock`}>
            <div className="row">
                <div className="col">
                    <span>
                        <strong>{getRoomName()}</strong>
                    </span>
                    <button type="button" className="btn btn-link right" onClick={handleButtonEdit}>
                        Edit
                    </button>
                    <button type="button" className="btn btn-link red right" onClick={handleButtonDelete}>
                        Delete
                    </button>
                </div>
            </div>
            {props.room.attributes.beds.map((bed: BedInput, index) => {
                return (
                    <div key={index} className="row">
                        <div className="col">
                            <span>
                                {bed.bed_size.name}, {bed.bed_type.name}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
